import React, {ReactElement, useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import Donation from "../../../../interfaces/Donation";
import DateUtils from "../../../../utils/DateUtils";
import styles from "./UserDonation.module.scss";
import {Toolbar} from "primereact/toolbar";
import {Dropdown} from "primereact/dropdown";
import {Toast} from "primereact/toast";
import UserDonationController from "./UserDonationController";
import {Loading} from "../../../../components/loading/Loading";

interface UserPremioProps {
    list: Array<Donation>;
    cpf: string | undefined;
}

export function UserDonation(props: UserPremioProps): ReactElement {
    const [status, setStatus] = useState(null);
    const [total, setTotal] = useState(10);
    const [donations, setDonations] = useState<Array<Donation>>(props.list);
    const toast = useRef<Toast>(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const controller = React.useCallback(
        () => new UserDonationController(setIsResponseLoading, toast),
        [setIsResponseLoading, toast]
    );

    useEffect(() => {
        async function getDonations(): Promise<void> {
            const {cpf} = props;
            if (cpf) {
                setDonations(await controller().getDonations(cpf, status, total));
            }
        }

        if (donations.length !== 0) {
            getDonations();
        }
    }, []);

    if (props.list?.length === 0 || props.cpf === undefined) {
        return <></>;
    }

    const createdAt = (row: Donation): string => {
        return DateUtils.format(row.createdAt);
    };

    const updatedAt = (row: Donation): string => {
        const date = row.updatedAt;
        if (!date) {
            return "NÃO UTILIZADO";
        }
        return DateUtils.format(date);
    };


    const buildOng = (row: Donation): ReactElement => {
        return <div className={styles.row}>
            <a target="_blank" rel="noopener noreferrer" href={row.ongLink}>
                <img src={row.ongLogo}
                     alt={`Logo da ${row.ongName}`}
                     width="50px"/>
            </a>
        </div>
    }

    const statusOp = [
        {label: "Todos", value: null},
        {label: "Pendente", value: "pendente"},
        {label: "Resgatado", value: "resgatado"},
    ];

    const totalOp = [
        {label: "10", value: 10},
        {label: "100", value: 100},
        {label: "500", value: 500},
        {label: "1000", value: 1000},
        {label: "Todos", value: null},
    ];

    const leftContents = (
        <>
            <h4 className={styles.padding}>Últimos:</h4>
            <Dropdown
                optionLabel="label"
                optionValue="value"
                value={total}
                options={totalOp}
                onChange={(e) => setTotal(e.value)}
                placeholder="Todos"
            />
            <h4 className={styles.padding}>Status:</h4>
            <Dropdown
                optionLabel="label"
                optionValue="value"
                value={status}
                options={statusOp}
                onChange={(e) => setStatus(e.value)}
                placeholder="Todos"
            />
            <div className="p-toolbar-separator mr-2"/>
        </>
    );

    return (
        <>
            <Loading isLoading={isResponseLoading}/>
            <Toast ref={toast}/>
            <div className={styles.UserDonation}>
                <Toolbar left={leftContents} className={styles.row}/>
                <div className={styles.row}>
                    <DataTable
                        showGridlines
                        value={donations}
                        scrollable
                        scrollHeight="70vh"
                    >
                        <Column header="Ong" body={buildOng}/>
                        <Column field="docId" header="Doc Fiscal"/>
                        <Column field="status" header="Status"/>
                        <Column header="Criado em" body={createdAt}/>
                        <Column header="Utilizado em" body={updatedAt}/>
                    </DataTable>
                </div>
            </div>
        </>
    );
}
