import Extrato from "../../../interfaces/Extrato";
import Saque from "../../../interfaces/Saque";
import Compartilhamento from "../../../interfaces/Compartilhamento";
import Donation from "../../../interfaces/Donation";
import Bilhete from "../../../interfaces/Bilhete";
import Pesquisa from "../../../interfaces/Pesquisa";
import {Accordion, AccordionTab} from "primereact/accordion";
import {UserExtrato} from "./user_extrato/UserExtrato";
import {UserSaque} from "./user_saque/UserSaque";
// eslint-disable-next-line max-len
import {UserCompartilhamento} from "./user_compartilhamento/UserCompartilhamento";
import {UserDonation} from "./user_donation/UserDonation";
import {UserBilhete} from "./user_bilhete/UserBilhete";
import {UserPesquisa} from "./user_pesquisa/UserPesquisa";
import React, {ReactElement} from "react";
import UserController from "../UserController";
import MissaoUser from "../../../interfaces/MissaoUser";
import {UserMissao} from "./user_missao/UserMissao";

interface UserAccordionProps {
    cpf: string | undefined;
    extratos: Array<Extrato>;
    saques: Array<Saque>;
    compartilhamentos: Array<Compartilhamento>;
    donations: Array<Donation>;
    bilhetes: Array<Bilhete>;
    pesquisa: Array<Pesquisa>;
    missao: Array<MissaoUser>;
    index: Array<number>;
    controller: UserController;
    update: () => void;
}

export function UserAccordion(props: UserAccordionProps): ReactElement {
    return (
        <>
            <Accordion multiple activeIndex={props.index}>
                <AccordionTab tabIndex={0} header="Extrato">
                    <UserExtrato list={props.extratos}/>
                </AccordionTab>
                <AccordionTab tabIndex={1} header="Solicitações de Saque">
                    <UserSaque list={props.saques} controller={props.controller}/>
                </AccordionTab>
                <AccordionTab tabIndex={2} header="Compartilhamentos">
                    <UserCompartilhamento list={props.compartilhamentos}/>
                </AccordionTab>
                <AccordionTab tabIndex={3} header="Doações">
                    <UserDonation list={props.donations} cpf={props.cpf}/>
                </AccordionTab>
                <AccordionTab tabIndex={4} header="Bilhetes">
                    <UserBilhete list={props.bilhetes} cpf={props.cpf}/>
                </AccordionTab>
                <AccordionTab tabIndex={5} header="Pesquisas">
                    <UserPesquisa list={props.pesquisa} cpf={props.cpf}/>
                </AccordionTab>
                <AccordionTab tabIndex={6} header="Missões">
                    <UserMissao list={props.missao} update={props.update}/>
                </AccordionTab>
            </Accordion>
        </>
    );
}

export default UserAccordion;
