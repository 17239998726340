import {Dispatch, RefObject, SetStateAction} from "react";
import {Toast} from "primereact/toast";
import UserService from "../../../../services/UserService";
import Donation from "../../../../interfaces/Donation";

class UserDonationController {
    private readonly dispatchLoading: Dispatch<SetStateAction<boolean>>;
    private readonly toast: RefObject<Toast>;

    constructor(
        dispatchLoading: Dispatch<SetStateAction<boolean>>,
        toast: RefObject<Toast>
    ) {
        this.dispatchLoading = dispatchLoading;
        this.toast = toast;
    }

    async getDonations(
        cpf: string,
        status: string | null,
        size: number | null
    ): Promise<Array<Donation>> {
        try {
            this.dispatchLoading(true);
            const response = await UserService.getDonations(cpf, status, size);
            if (response.status === 200) {
                return response.data.data.premios;
            }
        } catch (e) {
            console.error(e);
            this.toast.current?.show({severity: "error", detail: "Erro"});
        } finally {
            this.dispatchLoading(false);
        }
        return [];
    }
}

export default UserDonationController;
