import api from "./Api";
import {AxiosResponse} from "axios";
import token from "./TokeService";

const searchUser = (param: string): Promise<AxiosResponse> => {
    return api.get(`/search_user?token=${token()}&data=${param}`);
};

const infoUser = (param: string, psql: boolean): Promise<AxiosResponse> => {
    return api.get(`/user_info?token=${token()}&cpf=${param}&psql=${psql}`);
};

const getLogUser = (param: string): Promise<AxiosResponse> => {
    return api.get(`/log/action?token=${token()}&cpf=${param}`);
};

const getLogRequestsUser = (param: string): Promise<AxiosResponse> => {
    return api.get(`/log/requests?token=${token()}&cpf=${param}`);
};

const getLogErrorUser = (param: string): Promise<AxiosResponse> => {
    return api.get(`/log/error?token=${token()}&cpf=${param}`);
};

const getMissaoUser = (param: string): Promise<AxiosResponse> => {
    return api.get(`/missao/find?token=${token()}&cpf=${param}`);
};

const getPremios = (
    cpf: string,
    status: string | null,
    size: number | null
): Promise<AxiosResponse> => {
    return api.get(
        `/user_award?token=${token()}&cpf=${cpf}&status=${status ?? ""}&size=${
            size ?? ""
        }`
    );
};

const getBilhetes = (
    cpf: string,
    size: number | null
): Promise<AxiosResponse> => {
    return api.get(
        `/promocoes/bilhetes?token=${token()}&cpf=${cpf}&size=${size ?? ""}`
    );
};

const getPesquisa = (
    cpf: string,
    size: number | null
): Promise<AxiosResponse> => {
    return api.get(
        `/opiniao/pesquisa?token=${token()}&cpf=${cpf}&size=${size ?? ""}`
    );
};

const postPesquisa = (body: {
    _id: number;
    cpf: string;
}): Promise<AxiosResponse> => {
    return api.post(`/opiniao/pesquisa?token=${token()}`, body);
};

const sendEmailTempPassword = (param: string): Promise<AxiosResponse> => {
    return api.get(`/reset_password?token=${token()}&cpf=${param}`);
};

const abrirComprovante = (param: string): Promise<AxiosResponse> => {
    return api.get(
        `/solicitacao_saque/comprovante_transferencia?token=${token()}&id=${param}`
    );
};

const cadastrarCompartilhamento = (body: {
    _id: string;
    convidado: string;
}): Promise<AxiosResponse> => {
    return api.post(`/cadastrar_compartilhamento?token=${token()}`, body);
};

const alterarEmailUsuario = (body: {
    _id: string;
    email_atual: string;
    email_novo: string;
}): Promise<AxiosResponse> => {
    return api.post(`/alterar_email?token=${token()}`, body);
};

const cancelarSolicitacaoExclusao = (id: string): Promise<AxiosResponse> => {
    return api.post(`/user_solicitacao_exclusao?token=${token()}&userId=${id}`);
};

const genTempPassword = (body: { cpf: string }): Promise<AxiosResponse> => {
    return api.post(`/reset_password?token=${token()}`, body);
};

const getUrlUpdateSaldo = (cpf: string): string => {
    return `${process.env.REACT_APP_API_ENDPOINT}/refresh_balance?cpf=${cpf}`;
};

const getUrlListNotas = (cpf: string, year: number, month: number): string => {
    return `${
        process.env.REACT_APP_BASE_URL
    }/html/notas.html?token=${token()}&cpf=${cpf}&year=${year}&month=${month}`;
};

const getUrlListNotasByYear = (cpf: string, year: number): string => {
    return `${
        process.env.REACT_APP_BASE_URL
    }/html/notas.html?token=${token()}&cpf=${cpf}&year=${year}`;
};

const getPixInfo = (param: string): Promise<AxiosResponse> => {
    return api.get(`/solicitacao_saque/pix_info?token=${token()}&cpf=${param}`);
};

const refreshMissaoUser = (param: string): Promise<AxiosResponse> => {
    return api.get(`/missao/refresh?token=${token()}&cpf=${param}`);
};

const UserService = {
    searchUser,
    infoUser,
    getDonations: getPremios,
    getBilhetes,
    getPesquisa,
    postPesquisa,
    getUrlUpdateSaldo,
    getUrlListNotas,
    genTempPassword,
    getUrlListNotasByYear,
    sendEmailTempPassword,
    abrirComprovante,
    cadastrarCompartilhamento,
    alterarEmailUsuario,
    cancelarSolicitacaoExclusao,
    getLogUser,
    getLogRequestsUser,
    getLogErrorUser,
    getPixInfo,
    getMissaoUser,
    refreshMissaoUser,
};

export default UserService;
