import React, {ReactElement, useState} from "react";
import styles from "./UserDataTable.module.scss";
import {UserExtrato} from "./user_extrato/UserExtrato";
import Extrato from "../../../interfaces/Extrato";
import {TabPanel, TabView} from "primereact/tabview";
import Saque from "../../../interfaces/Saque";
import {UserSaque} from "./user_saque/UserSaque";
import Compartilhamento from "../../../interfaces/Compartilhamento";
// eslint-disable-next-line max-len
import {UserCompartilhamento} from "./user_compartilhamento/UserCompartilhamento";
import Donation from "../../../interfaces/Donation";
import {UserDonation} from "./user_donation/UserDonation";
import {UserBilhete} from "./user_bilhete/UserBilhete";
import Bilhete from "../../../interfaces/Bilhete";
import Pesquisa from "../../../interfaces/Pesquisa";
import {UserPesquisa} from "./user_pesquisa/UserPesquisa";
import UserAccordion from "./UserAccordion";
import UserController from "../UserController";
import MissaoUser from "../../../interfaces/MissaoUser";
import {UserMissao} from "./user_missao/UserMissao";

interface UserDataTableProps {
    cpf: string | undefined;
    extratos: Array<Extrato>;
    saques: Array<Saque>;
    compartilhamentos: Array<Compartilhamento>;
    donations: Array<Donation>;
    bilhetes: Array<Bilhete>;
    pesquisa: Array<Pesquisa>;
    missao: Array<MissaoUser>;
    enableScroll: boolean;
    controller: UserController;
    update: () => void;
}

export function UserDataTable(props: UserDataTableProps): ReactElement {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    return (
        <>
            <div className={styles.UserDataTable}>
                <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                    scrollable={props.enableScroll}
                >
                    <TabPanel header="Todos">
                        <UserAccordion
                            cpf={props.cpf}
                            extratos={props.extratos}
                            saques={props.saques}
                            compartilhamentos={props.compartilhamentos}
                            donations={props.donations}
                            bilhetes={props.bilhetes}
                            pesquisa={props.pesquisa}
                            index={[0, 1, 2, 3, 4, 5]}
                            controller={props.controller}
                            missao={props.missao}
                            update={props.update}
                        />
                    </TabPanel>
                    <TabPanel header="Extrato">
                        <UserExtrato list={props.extratos}/>
                    </TabPanel>
                    <TabPanel header="Solicitações de Saque">
                        <UserSaque list={props.saques} controller={props.controller}/>
                    </TabPanel>
                    <TabPanel header="Compartilhamentos">
                        <UserCompartilhamento list={props.compartilhamentos}/>
                    </TabPanel>
                    <TabPanel header="Premios">
                        <UserDonation list={props.donations} cpf={props.cpf}/>
                    </TabPanel>
                    <TabPanel header="Bilhetes">
                        <UserBilhete list={props.bilhetes} cpf={props.cpf}/>
                    </TabPanel>
                    <TabPanel header="Pesquisas">
                        <UserPesquisa list={props.pesquisa} cpf={props.cpf}/>
                    </TabPanel>
                    <TabPanel header="Missões">
                        <UserMissao list={props.missao} update={props.update}/>
                    </TabPanel>
                </TabView>
            </div>
        </>
    );
}
